@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Chewy&family=Fredoka:wght@300..700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chewy&family=Fredoka:wght@300..700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #3c80fb;
}

.stroke-text {
  text-shadow: -3px -3px 0 #fff, 3px -3px 0 #fff, -3px 2px 0 #fff, 3px 3px 0 #fff;
}

.nav_btn {
  background-color: transparent;
  background-image: linear-gradient(180deg, #01088d 0%, #3c80fb 100%);
  border-radius: 100px 100px 100px 100px;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
    /* Adjust the distance you want the image to move */
  }
}

.animate-bounce {
  animation: bounce 2s infinite;
}

.marque {
  background-color: transparent;
  background-image: linear-gradient(180deg, #01088d 0%, #3c80fb 100%);
}


.marquee {
  animation: marquee 18s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.scroll-container {
  animation: scroll 10s linear infinite;
  /* Adjust animation duration as needed */
  display: flex;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}


.image {
  animation: rotateImage 5s linear infinite;
}

@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bg_img {
  background-image: url('media/images/Ellipse-2467.png');
  background-repeat: no-repeat;
  background-size: 50% 115%;
}

.bg_img2 {
  background-image: url('media/images/com-background.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0 14px;
}

@media screen and (max-width: 768px) {
  .bg_img2 {
    background-image: url('media/images/com-background.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 14px;
    border-radius: 10px;
  }
}